
<template>
  <el-tooltip
    effect="dark"
    content="全屏展示"
    placement="top"
  >
    <el-button
      icon="iconfont icon-quanping"
      size="small"
      class="screen-btn"
      type="primary"
      @click="dialogVisible = true"
    ></el-button>
  </el-tooltip>
  <el-dialog
    title="商品详情"
    v-model="dialogVisible"
    custom-class="table-dialog"
    top="24px"
    width="100%"
  >
    <chartTable
      :state="state"
      :tableMaxHeight="tableMaxHeight"
      @resetTable="resetTable"
      @stateChange="stateChange"
    >
    </chartTable>
  </el-dialog>
</template>

<script>
import { nextTick, ref, watch } from 'vue'
import chartTable from '@/views/Goods/goods-detail-table'
import './open-table.scss'

export default {
  props: {
    state: {
      type: Object
    }
  },
  emits: {
    resetTable: null,
    stateChange: null
  },
  components: { chartTable },
  setup (props, { emit }) {
    const dialogVisible = ref(false)
    watch(dialogVisible, () => {
      if (dialogVisible.value) {
        history.pushState(null, null, document.URL)
        nextTick(() => {
          computedTableMaxHeight()
        })
      } else {
        window.history.back()
      }
    })

    const tableMaxHeight = ref('0')
    const computedTableMaxHeight = () => {
      tableMaxHeight.value = `${document.documentElement.clientHeight - 202}`
    }

    window.addEventListener('popstate', () => {
      if (dialogVisible.value) {
        history.pushState(null, null, document.URL)
      }
    })
    window.onresize = () => {
      computedTableMaxHeight()
    }

    const resetTable = (row) => emit('resetTable', row)
    const stateChange = (row) => emit('stateChange', row)
    return { dialogVisible, resetTable, stateChange, tableMaxHeight }
  }
}
</script>
