<template>
  <div class="goodsDetail">
    <div class="fixDiv" :class="{'position-bottom': !noData}">
      <noData
        v-show="!noData"
        text="无相关数据，请在其他页面选择单一商品进行查看～"
      ></noData>
      <div class="fix-box" :class="{ 'box-shandow': boxShandow }" v-if="noData">
        <div class="card">
          <div
            class="card_left"
            :class="{ 'no-card-left': !state.goodsDetail.pro_pic_link }"
          >
            <img
              :src="state.goodsDetail.pro_pic_link"
              v-if="state.goodsDetail.pro_pic_link"
            />
            <img class="" src="@/assets/no-data/goods-detail.png" v-else />
          </div>
          <div class="card_right">
            <a @click="goGoodsDetail">{{ state.goodsDetail.pro_title }}</a>
            <div>
              <span class="avg-price">
                今日价格
                <el-tooltip
                  popper-class="search-tooltip"
                  append-to-body
                  effect="dark"
                  placement="top"
                >
                  <template #content>
                    <header>今日价格:</header>
                    通过商品页面原价，智能叠加促销活动后，得到的到手价
                  </template>
                  <span class="iconfont icon-bangzhu"></span>
                </el-tooltip>
                {{ state.goodsDetail.today_price }}
              </span>
              <span
                >均价
                <el-tooltip
                  popper-class="search-tooltip"
                  append-to-body
                  effect="dark"
                  placement="top"
                >
                  <template #content>
                    <header>均价:</header>
                    商品均价=商品总销售金额/商品总销售件数
                  </template>
                  <span class="iconfont icon-bangzhu"></span>
                </el-tooltip>
                {{ state.goodsDetail.avg_price }}
                <i
                  >（{{ state.goodsDetail.startTime }}-{{
                    state.goodsDetail.endTime
                  }}）</i
                ></span
              >
            </div>
          </div>
        </div>
        <div class="nav">
          <div class="nav_left">
            <div class="nav_a">
              <i class="iconfont icon-shangpinxiangqing_pingtai"></i>
              <span>平台：{{ state.goodsDetail.platform_type || "" }}</span>
            </div>
            <div class="nav_a">
              <i class="iconfont icon-shangpinxiangqing_dianpu"></i>
              <span>店铺：{{ state.goodsDetail.shop_name }}</span>
            </div>
          </div>
          <div class="nav_right">
            <datePicker
              @onDateValueEvent="onDateValueEvent"
              :excludeTime="excludeTime"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="calendar" v-show="noData">
      <div class="calendar-header">
        <div class="color_left">
          <div class="nav_a">
            <i class="iconfont icon-rili"></i>
            <span>促销日历</span>
          </div>
          <div class="nav_a">
            <span>{{ state.calendarMonth }}</span>
          </div>
        </div>
        <div class="color_right">
          <el-button
            size="small"
            @click="changeCalendar('prev-month')"
            :disabled="isPrev"
            >上个月</el-button
          >
          <el-button
            size="small"
            @click="changeCalendar('today')"
            :disabled="isToday"
            >本月</el-button
          >
          <el-button
            size="small"
            @click="changeCalendar('next-month')"
            :disabled="isNext"
            >下个月</el-button
          >
          <el-tooltip
            effect="dark"
            :content="
              !state.downPrimaryCan.permission
                ? state.downPrimaryCan.message
                : '下载'
            "
            placement="top"
          >
            <div>
              <el-button
                :disabled="!state.downPrimaryCan.permission"
                icon="iconfont icon-xiazai"
                size="small"
                @click="downLoadCalendar"
              ></el-button>
            </div>
          </el-tooltip>
        </div>
      </div>
      <el-calendar id="calendar" ref="calendar" v-model="state.value">
        <template v-slot:dateCell="{ data }">
          <!--自定义内容-->
          <div>
            <div class="calendar-day">
              {{ Number(data.day.split("-").slice(2).join("-")) }}
            </div>
            <div v-for="(item, index) in state.calendarData" :key="index">
              <div
                v-if="
                  item.month.indexOf(data.day.split('-')[1]) != -1 &&
                  item.years.indexOf(data.day.split('-')[0]) != -1
                "
              >
                <!-- 非当前月 -->
                <div v-if="item.days.indexOf(data.day.split('-')[2]) != -1">
                  <el-popover
                    placement="bottom-start"
                    title=""
                    :width="330"
                    trigger="hover"
                    v-for="(event, index) in item.things"
                    :key="index"
                    popper-class="propper22"
                    :append-to-body="false"
                  >
                    <!-- popover自定义内容 -->
                    <div class="diaCard">
                      <img
                        v-show="event.id === 1"
                        class="topimg"
                        src="@/assets/goodsdetail/red/redlt.png"
                        alt=""
                      />
                      <img
                        v-show="event.id === 2"
                        class="topimg"
                        src="@/assets/goodsdetail/yellow/yellowlt.png"
                        alt=""
                      />
                      <img
                        v-show="event.id === 3"
                        class="topimg"
                        src="@/assets/goodsdetail/green/greenlt.png"
                        alt=""
                      />
                      <img
                        v-show="event.id === 4"
                        class="topimg"
                        src="@/assets/goodsdetail/blue/bluelt.png"
                        alt=""
                      />
                      <div class="dc_top">
                        <img
                          v-show="event.id === 1"
                          src="@/assets/goodsdetail/red/imgt.png"
                          alt=""
                        />
                        <img
                          v-show="event.id === 2"
                          src="@/assets/goodsdetail/yellow/imgt.png"
                          alt=""
                        />
                        <img
                          v-show="event.id === 3"
                          src="@/assets/goodsdetail/green/imgt.png"
                          alt=""
                        />
                        <img
                          v-show="event.id === 4"
                          src="@/assets/goodsdetail/blue/imgt.png"
                          alt=""
                        />
                        <div class="spanBox">
                          <span>{{ event.th }}</span>
                          <span class="spanMonth">{{
                            item.month + "月" + item.days + "日"
                          }}</span>
                        </div>
                        <div class="spanRight">
                          {{ item.month + "月" + item.days + "日" }}
                        </div>
                      </div>
                      <div class="scroll-y">
                        <div
                          v-for="(con, index) in event.content"
                          :key="index"
                          :class="[
                            event.id === 1
                              ? 'dc_cont'
                              : event.id === 2
                              ? 'dc_cont_yellow'
                              : event.id === 3
                              ? 'dc_cont_green'
                              : 'dc_cont_blue',
                          ]"
                        >
                          <div class="cont-header">{{ con.type }}</div>
                          <img
                            v-show="event.id === 1"
                            class="bgtop"
                            src="@/assets/goodsdetail/red/bgtop.png"
                            alt=""
                          />
                          <img
                            v-show="event.id === 2"
                            class="bgtop"
                            src="@/assets/goodsdetail/yellow/bgtop.png"
                            alt=""
                          />
                          <img
                            v-show="event.id === 3"
                            class="bgtop"
                            src="@/assets/goodsdetail/green/bgtop.png"
                            alt=""
                          />
                          <img
                            v-show="event.id === 4"
                            class="bgtop"
                            src="@/assets/goodsdetail/blue/bgtop.png"
                            alt=""
                          />
                          <div
                            v-for="(con, i) of con.list"
                            :key="i"
                          >
                            <p>
                              <i class="iconfont"></i>
                            </p>
                            <div class="dc_card">
                              <img
                                v-if="event.id === 1"
                                src="@/assets/goodsdetail/red/lingquan.png"
                                alt=""
                              />
                              <img
                                v-if="event.id === 2"
                                src="@/assets/goodsdetail/yellow/lingquan.png"
                                alt=""
                              />
                              <img
                                v-if="event.id === 3"
                                src="@/assets/goodsdetail/green/lingquan.png"
                                alt=""
                              />
                              <img
                                v-if="event.id === 4"
                                src="@/assets/goodsdetail/blue/lingquan.png"
                                alt=""
                              />
                              <span>{{ con }}</span>
                              <!-- <span>最多减500</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template #reference>
                      <div
                        :class="[
                          event.id == 1
                            ? 'is-selected1'
                            : event.id == 2
                            ? 'is-selected2'
                            : event.id == 3
                            ? 'is-selected3'
                            : event.id == 4
                            ? 'is-selected4'
                            : '',
                          event.top === 1
                            ? 'is-selected-active'
                            : event.top === 0
                            ? 'is-selected-end'
                            : 'is-selected-continuous',
                          event.end === 1 ? 'is-selected-one' : '',
                        ]"
                      >
                        <div
                          class="labelSpan"
                          v-if="event.top === 1 || event.end === 1"
                        >
                          {{ event.th }}
                        </div>
                        <!-- {{ event.id }} -->
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div></div>
              </div>
              <div v-else></div>
            </div>
          </div>
        </template>
      </el-calendar>
    </div>
    <goodsDetailTable
      v-show="noData"
      :state="state"
      @resetTable="resetTable"
      @stateChange="stateChange"
    >
      <template v-slot:screen>
        <goodsDetailOpenTable
          :state="state"
          @resetTable="resetTable"
          @stateChange="stateChange"
        >
        </goodsDetailOpenTable>
      </template>
    </goodsDetailTable>
  </div>
</template>

<script>
import './goods-detail.scss'
import { defineComponent, reactive, ref, watch, computed, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/utils/request'
import { useStore } from 'vuex'
import datePicker from '@/components/date-picker.vue'
import watermark from '@/components/directives'
import { dateFormatter, screenShot, getTime } from '@/utils/index'
import tableFirstHeaderScroll from '@/components/table-first-header-scroll'
import noData from '@/components/no-data'
import { ElMessage } from 'element-plus'
import goodsDetailTable from './goods-detail-table.vue'
import goodsDetailOpenTable from '@/components/chart-table/goods-detail-open-table'
import popoverScroll from './popover-scroll'
import { downloadEchartLog } from '@/utils/saveLog'

export default defineComponent({
  name: 'GoodsDetail',
  components: {
    datePicker,
    noData,
    goodsDetailTable,
    goodsDetailOpenTable
  },
  setup () {
    // 重置左侧导航
    document.querySelector('.menu-container').style.marginTop = '0'
    document.querySelector('.navi-container').style.opacity = 1

    const store = useStore()
    const phone = store.state.phone
    const end = dateFormatter(new Date(), 'YYYY-MM-DD')
    const excludeTime = reactive({ start: '1970', end })
    const urlLocal = localStorage.getItem(`${phone}/goods/detail_url`)
    const selTypeDateLocal = localStorage.getItem(`${phone}_table_date_type`)
    const state = reactive({
      tables: [],
      tableData: [],
      downPrimary: {},
      downPrimaryCan: {},
      date: '',
      check1: true,
      check2: true,
      check3: true,
      check4: true,
      selTypeDate: selTypeDateLocal || 'd',
      optionsDate: [],
      calendarData: [],
      goodsDetail: {},
      start_time: '',
      end_time: '',
      value: new Date(),
      calendarMonth: ''
    })
    watch(
      () => state.value,
      () => {
        const myDate = state.value ? new Date(state.value) : new Date()
        const year = myDate.getFullYear()
        const month = myDate.getMonth()
        state.calendarMonth = `${year}年${month + 1}月`
      },
      { immediate: true }
    )
    // 日历下载
    const downLoadCalendar = () => {
      screenShot(
        '#calendar',
        false,
        `${route.meta.title}-促销日历${getTime()}`
      )
      downloadEchartLog()
    }
    const route = useRoute()
    const { url, startTime, endTime } = route.query
    const dateLocal = JSON.parse(localStorage.getItem(`${phone}_date`)) || []
    const params = reactive({
      pro_link: url || urlLocal || '',
      start_time: startTime || (dateLocal ? dateLocal[0] : ''),
      end_time: endTime || (dateLocal ? dateLocal[1] : ''),
      date_type: 'd'
    })
    const setLocla = () => {
      localStorage.setItem(`${phone}/goods/detail_url`, params.pro_link)
      localStorage.setItem(
        `${phone}_date`,
        JSON.stringify([params.start_time, params.end_time])
      )
      localStorage.setItem(
        `${phone}_date_value`,
        `${params.start_time} - ${params.end_time}`
      )
    }

    state.value = params.start_time ? new Date(params.start_time) : new Date()
    state.calendarData = []
    const getDetail = () => {
      if (!params.pro_link || !params.start_time || !params.end_time) return
      axios
        .get('/api/v1/product_data/goods_detail', {
          params: { ...params },
          loading: true
        })
        .then((res) => {
          state.goodsDetail = res.data.goodsDetail || {}
          state.goodsDetail.startTime = params.start_time
          state.goodsDetail.endTime = params.end_time
          state.calendarData = res.data.calendarData
          state.downPrimaryCan = res.data.download_pms // false不可以下载
          nextTick(() => {
            popoverScroll()
          })
        })
    }

    let dates = []
    const getList = () => {
      if (!params.pro_link || !params.start_time || !params.end_time) return
      localStorage.setItem(`${phone}_table_date_type`, state.selTypeDate)
      axios
        .get('/api/v1/product_data/goods_table', {
          params: { ...params, date_type: state.selTypeDate },
          loading: true
        })
        .then((res) => {
          state.downPrimary = res.data.download_pms // false不可以下载
          state.tables = res.data.resDataList || []
          dates = res.data.dates || []
          resetTableColumn()
        })
    }

    const tableColumn = (i, exclude) => {
      const thildChildrenCopy = {
        full_y: [
          { label: '满减', prop: `${i}_full_y_reduction` },
          { label: '任选X件', prop: `${i}_full_y_choose_piece` },
          { label: '送赠品', prop: `${i}_full_y_gift` },
          { label: '送店铺券', prop: `${i}_full_y_shop_coupon` },
          { label: '包邮', prop: `${i}_full_y_free_shipping` }
        ],
        every_full_y: [
          { label: '满减', prop: `${i}_every_full_y_reduction` },
          { label: '包邮', prop: `${i}_every_full_y_free_shipping` }
        ],
        full_piece: [
          { label: '满减', prop: `${i}_full_piece_reduction` },
          { label: '满折', prop: `${i}_full_piece_discount` },
          { label: '减X件', prop: `${i}_full_piece_minus` },
          { label: '最低价享X元', prop: `${i}_full_piece_low_price` },
          { label: '包邮', prop: `${i}_full_piece_shipping` }
        ],
        no_threshold: [{ label: '送赠品', prop: `${i}_no_threshold_gift` }]
      }

      const thildChildren = {}
      Object.entries(thildChildrenCopy).forEach(([key, value]) => {
        const remainingChildren = value.filter(
          (v) => !exclude.includes(v.prop)
        )
        thildChildren[key] = remainingChildren.length ? remainingChildren : []
      })

      const submitArr = [
        {
          label: '满X元',
          prop: 'full_y',
          children: thildChildren.full_y
        },
        {
          label: '每满X元',
          prop: 'every_full_y',
          children: thildChildren.every_full_y
        },
        {
          label: '满X件',
          prop: 'full_piece',
          children: thildChildren.full_piece
        },
        {
          label: '无门槛',
          prop: 'no_threshold',
          children: thildChildren.no_threshold
        }
      ]

      return submitArr
    }

    const resetTable = (key) => {
      if (!state.check1 && !state.check2 && !state.check3 && !state.check4) {
        ElMessage({ message: '至少选择1个选项', type: 'error' })
        state[key] = true
      }
      resetTableColumn()
    }
    const stateChange = (obj) => {
      Object.entries(obj).forEach(([k, v]) => {
        state[k] = v
      })
    }

    const resetTableColumn = () => {
      const count = {}
      const length = state.tables.length
      // 获取所有无数据数组
      state.tables.forEach((d, i) => {
        Object.keys(d).forEach((dk) => {
          !d[dk] && (count[dk] = count[dk] ? count[dk] + 1 : 1)
        })
      })
      // 获取所有列为空的数据，需要过滤掉
      const exclude = Object.keys(count).filter((c) => count[c] === length)
      const secondTableColumn = dates.map((d, i) => {
        const columnCopy = tableColumn(i, exclude)
        const children = []
        if (state.check1 && columnCopy[0].children.length) {
          children.push(columnCopy[0])
        }
        if (state.check2 && columnCopy[1].children.length) {
          children.push(columnCopy[1])
        }
        if (state.check3 && columnCopy[2].children.length) {
          children.push(columnCopy[2])
        }
        if (state.check4 && columnCopy[3].children.length) {
          children.push(columnCopy[3])
        }
        return { label: d, children }
      })

      state.tableData = secondTableColumn.filter((c) => c.children.length)
      setTimeout(() => {
        watermark({ watermarl_element: 'downloadTable' })
        tableFirstHeaderScroll()
      }, 2000)
    }

    watch(
      () => [params.start_time, params.end_time],
      () => {
        const timeOptions = []
        const start = new Date(params.start_time)
        const end = new Date(params.end_time)
        const day = (end - start) / (24 * 60 * 60 * 1000)
        timeOptions.unshift({ label: '月', value: 'm', disabled: true })
        timeOptions.unshift({
          label: '周',
          value: 'w',
          disabled: day < 364,
          toolTip: '所选日期范围在364天以内才可以按周查看'
        })
        timeOptions.unshift({
          label: '日',
          value: 'd',
          disabled: day < 14,
          toolTip: '所选日期范围在14天以内才可以按日查看'
        })
        // 重置单选框value
        const selectValue = timeOptions.filter(
          (t) => t.value === state.selTypeDate && t.disabled
        )
        const selectOption = timeOptions.filter((t) => t.disabled)
        if (!selectValue.length && selectOption.length) {
          state.selTypeDate = selectOption[0].value
        }
        state.optionsDate.length = 0
        state.optionsDate.push(...timeOptions)

        state.value = start

        setLocla()
        getDetail()
        getList()
      },
      { immediate: true }
    )

    watch(
      () => state.selTypeDate,
      () => getList()
    )

    // 当时间改变的时候，触发详情接口
    const onDateValueEvent = (e) => {
      state.start_time = e[0]
      state.end_time = e[1]
      params.start_time = e[0]
      params.end_time = e[1]
    }

    const goGoodsDetail = () => {
      window.open(`https://${params.pro_link}`, '__blank')
    }

    const noData = ref(true)
    watch(
      state,
      () => {
        noData.value = !!state.goodsDetail.pro_title
      },
      { immediate: true }
    )

    const calendar = ref(null)
    const changeCalendar = (type) => {
      calendar.value.selectDate(type)
      nextTick(() => {
        popoverScroll()
      })
    }

    const isPrev = computed(() => {
      if (!params.start_time) {
        return false
      }
      const data = params.start_time.split('/')
      data[2] = 2
      const dataValue = new Date(data)
      const prevValue = new Date(
        `${state.value.getFullYear()}/${state.value.getMonth() + 1}/1`
      )
      return dataValue.getTime() >= prevValue.getTime()
    })
    const isToday = computed(() => {
      if (!params.start_time || !params.end_time) {
        return false
      }
      const start = params.start_time.split('/')
      start[2] = 1
      const startTime = new Date(start)
      const end = params.end_time.split('/')
      const endTime = new Date(end)
      const date = new Date()
      const prevValue = new Date(
        `${date.getFullYear()}/${date.getMonth() + 1}/1`
      )
      return startTime > prevValue || endTime < prevValue
    })
    const isNext = computed(() => {
      if (!params.end_time) {
        return false
      }
      const data = params.end_time.split('/')
      data[2] = 1
      const dataValue = new Date(data)
      return dataValue <= state.value
    })

    const boxShandow = ref(window.scrollY > 0)
    window.onscroll = () => {
      boxShandow.value = window.scrollY > 0
    }
    return {
      isPrev,
      isToday,
      isNext,
      noData,
      boxShandow,
      resetTable,
      stateChange,
      state,
      onDateValueEvent,
      excludeTime,
      downLoadCalendar,
      goGoodsDetail,
      changeCalendar,
      calendar
    }
  }
})
</script>
