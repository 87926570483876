<template>
  <div class="table">
    <div class="table_top">
      <div class="tbTop_l">
        <el-checkbox
          v-model="tableData.check1"
          @change="resetTable('check1')"
          label="满X元"
        ></el-checkbox>
        <el-checkbox
          v-model="tableData.check2"
          @change="resetTable('check2')"
          label="每满X元"
        ></el-checkbox>
        <el-checkbox
          v-model="tableData.check3"
          @change="resetTable('check3')"
          label="满X件"
        ></el-checkbox>
        <el-checkbox
          v-model="tableData.check4"
          @change="resetTable('check4')"
          label="无门槛"
        ></el-checkbox>
      </div>
      <div class="tbTop_r">
        <div>
          <span>按</span>
          <el-select
            v-model="tableData.selTypeDate"
            @change="selTypeChange"
            size="medium"
            placeholder="请选择"
          >
            <el-tooltip
              v-for="item in state.optionsDate"
              :key="item.value"
              :disabled="item.disabled"
              effect="dark"
              :content="item.toolTip"
              placement="top"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                :disabled="!item.disabled"
              >
              </el-option>
            </el-tooltip>
          </el-select>
          <span>查看</span>
        </div>
        <el-tooltip
          effect="dark"
          :content="
            !state.downPrimary.permission ? state.downPrimary.message : '下载'
          "
          placement="top"
        >
          <div>
            <el-button
              :disabled="!state.downPrimary.permission"
              icon="iconfont icon-xiazai"
              size="small"
              @click="download('downloadTable', downloadName)"
            ></el-button>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="tableBox">
      <noData v-show="!state.tableData.length"></noData>
      <template v-if="!!state.tableData.length">
        <slot name="screen"></slot>
      </template>
      <el-table
        id="downloadTable"
        v-show="!!state.tableData.length"
        :data="state.tables"
        :max-height="tableMaxHeight"
        :header-cell-style="{padding: '7px 0'}"
      >
        <el-table-column fixed type="index" label="序号" width="61">
        </el-table-column>
        <el-table-column
          v-for="(s, i) of state.tableData"
          :key="i"
          :label="s.label"
          header-align="center"
        >
          <el-table-column
            v-for="(sc, i) of s.children"
            :key="i"
            prop="name"
            :label="sc.label"
            header-align="center"
            min-width="120"
          >
            <el-table-column
              v-for="(scc, i) of sc.children"
              :key="i"
              :prop="scc.prop"
              :label="scc.label"
              header-align="left"
              min-width="120"
            >
              <template #default="{ row }">
                <span>
                  {{ row[scc.prop] || "-" }}
                </span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import noData from '@/components/no-data'
import { reactive, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { tableToExcel, getTime } from '@/utils/index'
import { downloadTableLog } from '@/utils/saveLog'

export default {
  components: { noData },
  props: {
    state: {
      type: Object
    },
    tableMaxHeight: {
      type: String,
      default: '635'
    }
  },
  setup (props, { emit }) {
    const tableData = reactive({})
    watch(props.state, () => {
      Object.entries(props.state).forEach(([key, value]) => {
        tableData[key] = value
      })
    }, { immediate: true })

    const resetTable = (key) => {
      emit('stateChange', tableData)
      emit('resetTable', key)
    }
    const selTypeChange = () => emit('stateChange', tableData)

    const download = (idName, downloadName) => {
      tableToExcel(idName, downloadName)
      downloadTableLog()
    }
    const route = useRoute()
    const downloadName = computed(() => {
      return `${route.meta.title}-商品表${getTime()}`
    })
    return { tableData, resetTable, tableToExcel, download, downloadName, selTypeChange }
  }
}
</script>
