export default function popoverScroll () {
  if (!document.querySelector('.scroll-y')) return
  document.querySelectorAll('.scroll-y').forEach(el => {
    el.onscroll = (e) => {
      const scrollTop = e.target.scrollTop * 2
      const isScroll = scrollTop > 24
      const parent = e.path[1].querySelector('.dc_top')
      parent.querySelectorAll('img').forEach(img => {
        img.style.width = `${isScroll ? 24 : 48 - scrollTop}px`
        img.style.height = `${isScroll ? 24 : 48 - scrollTop}px`
      })
      parent.querySelector('.spanMonth').style.opacity = (10 - scrollTop) / 10
      parent.querySelector('.spanMonth').style.height = `${scrollTop > 16 ? 0 : 16 - scrollTop}px`
      parent.querySelector('.spanMonth').style.marginTop = `${4 - scrollTop}px`
      parent.nextElementSibling.style.maxHeight = `${304 + (scrollTop > 24 ? 24 : scrollTop)}px`
      if (scrollTop > 10) {
        parent.querySelector('.spanRight').style.opacity = scrollTop > 20 ? 1 : (20 - scrollTop) / 20
      } else {
        parent.querySelector('.spanRight').style.opacity = 0
      }
    }
  })
}
